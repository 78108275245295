:root{
  --timechangebg: 0.3s;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background var(--timechangebg) ease-in-out;
}
a{
  text-decoration: none;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  margin: 0;
  list-style-type: none;
}
.App-header{
  background: #051947;

}
.App-header--mobile{
  position: fixed;
  height: 10vh;
  top :0;
  z-index: 500;
  margin: 0;
  width: 100vw;
}
.nav--mobile{
position: fixed;
right: -150vh;
width: 60vw;
top: 0;
height: 110vh;
background: rgb(2,0,36);
background: linear-gradient(100deg, rgba(2,0,36,1) 0%, rgba(8,8,129,1) 35%, rgba(7,21,108,1) 100%);
z-index: 500;
transition: right 0.5s;

}
.nav--mobile--visible{
  right: 0;

}
#nav__ul--mobile{
  height: 100%;
   padding-top: 30vh;
   padding-left: 0;
}
#nav__ul--mobile li {
  text-align: center;
  padding-top: 10%;
  color: white;
  cursor: pointer;

}
#nav__toogle--mobile{
position: absolute;
right: 0;
margin-right: 4vw;
top:2.5vh;
color: white;
font-size: 200%;
}
.nav{
  position: fixed;
  top:0;
  background: rgb(0,31,103);
  background: linear-gradient(90deg, rgba(0,31,103,1) 17%, rgba(5,25,72,1) 58%, rgba(5,25,71,1) 59%, rgba(4,97,116,1) 100%);
  width: 100vw;
  height: 15vh;
  z-index: 500;
  padding-bottom: 10px;
}
.nav__ul{
  display: flex;
  width: 40vw;
  text-align: right;
  padding-top: 4vh;
  flex-direction: row;
  float: right;
}
.nav__ul li{
  margin-right: 3%;
  width: max-content;
  color: white;
  font-size: 120%;
  cursor: pointer;
}

/* logo */

.logo{
  width: 7vw;
  padding-top: 1vh;

  filter: invert(100);
  padding-left: 5vw;
}

.logo--mobile{
  width: 15vw;
  max-width: 90px !important;
  max-height: 9.5vh;
  
}

.div--lightMode{
  display: inline-block;
  background: rgb(1,25,138);
background: linear-gradient(100deg, rgba(1,25,138,1) 43%, rgba(72,174,255,1) 99%);
  width: 6vw;
height: max-content;

  border-radius: 15%;
  position: absolute;
  margin-top: 4vh;
  margin-left: 3vw;
  padding: 4px 2px;
}
.div--lightMode--mobile{
  width: 15vw;
  margin-bottom:2vh ;
  margin-top: 2.3vh;
}
.div--lightMode p{
  height: 2vh;
  position: relative;
font-size: 1.5vw;
transform: scale(1.4);
background-color: #13072E;
width: max-content;
height: max-content;
  border-radius: 50%;
  padding: 3px;
  display: inline-block;
  transition: background, color .5s;
  margin: 0;

}
.div--lightMode--mobile p{
  font-size: 4vw;
}
.div--lightMode__p--animationDay{
  animation: change-day-mode 1s ease;
  background-color: black;
}
.div--lightMode__p--animationNight{
  animation: change-night-mode 1s ease;
  background-color: black;
}
.p--lightmode--day{
  left: 77%;
  text-align: center;
  background-color: white  !important;
  color: #CBAC00;
}
.p--lightmode--night{
  text-align: center;
  background-color: black;
  color:   yellow;
  left: 0%;
}
@keyframes change-day-mode {
  from {
    left:0;
  }
  to {
    left: 77%;
  }
}
@keyframes change-night-mode {
  from {
    left:77%;
  }
  to {
    left: 0%;
  }
}

/* welcomepage */
.container__div--welcomePage{
  background: rgb(22,11,228);
background: linear-gradient(150deg, rgba(22,11,228,1) 27%, rgba(4,27,79,1) 63%);
transition: opacity 4s ease;


}
.container__div--welcomePage--mobile{
  margin-top: 10vh;
}
.div--welcomePage{
  display: flex;

  color: wheat;
}
.div--welcomePage__message{
flex-grow: 1;
}
.div--welcomePage__message p{
  margin-top: 10%;
  font-size: 5.5vw;
  padding-left: 10%;
  margin-bottom: 5%;

}
.div--welcomePage__message h2 ,.div--AboutUsPage h3{
  font-size: 7vw;
  text-align: center;
  margin: 0;
}

.div--welcomePage__img {
  width: 60%;
}
.div--welcomePage__img img{

  padding-top: 5vw;
  max-width: 100%;
}
.App__body__desktop{
  margin-top: 15vh;
}
/* buttons */
.button{
 border: none;
 background: wheat;
 border-radius: 8px;
 padding: 4px 10px;
 font-size: 2vw;
 margin-left: 25%;
 margin-top: 5%;
 margin-top: 2%;
 cursor: pointer;
 transition: background var(--timechangebg) ease-in-out;
}
.button a{
  color: black;
}
.Button--pricingPage{
  margin: 0;
  margin-top: 10px;
  text-align: left;
}
.Button--pricingPage--mobile,.Button--welcomePage--mobile{
  font-size: 4vw;
}
.div__i--arrow{
  background-color: #AD8805;
  color: white;
  padding: 4px;
  font-size: 80%;
  border-radius: 50%;
}
.arrow--prcing{
  background-color: #13072E;
}

/* About US */
.div--AboutUsPage{
  background: rgb(0,0,56);
  background: linear-gradient(100deg, rgba(0,0,56,1) 43%, rgba(0,0,0,1) 99%);
  padding-bottom: 1vh;
}
.div--AboutUs{
  display: flex;

}
.div--AboutUs--mobile{
  flex-direction: column;
}
.div--AboutUsPage h3{
  color: white;
  font-size: 7vw;
  text-transform: uppercase;
}
.div__AboutUs--message{
  color: white;
  width: 60vw;
}
.div--AboutUs--mobile div{
  width: 100%;
}
.div__AboutUs--image img {
  max-width: 95%;
  min-width: 80%;
  text-align: center;
}
.div--AboutUs--image--mobile{
  text-align: center;
  margin: auto;
} 
.div--AboutUs--image--mobile img{

  max-width: 100%;
}
.div__AboutUs--message p{
  text-align: center;
  font-size: 2.5vw;
}
.div__AboutUs--message a{
  color: black;
  display: block;
  font-size: 2vw;
  color: white;
  text-align: center;
  padding: 2% 0;
}
.div--AboutUs--message--mobile p{
  font-size: 4vw;
  margin-right: 10px;
  margin-left: 10px;
}
.div--AboutUs--message--mobile a{
  font-size: 3vw;
}
.div--AboutUs--card{
  display: flex;
  padding-left: 10vw;
  margin-top: 2vh;
  

}
.div--AboutUs--card--mobile{
  flex-direction: column;

  margin-top: 1vh;
}
.div--AboutUs--card ul{
  width: 40vw;
  border-radius: 10px;
  background: rgb(7,12,46);
  margin-right: 3vw;
  color: white;
  text-align: center;
  background: linear-gradient(135deg, rgba(7,12,46,1) 25%, rgba(33,46,130,1) 99%);
  padding: 1% 2%;
  padding-top: 0;

}
.div--AboutUs--card--mobile ul{
  width: 87%;
  margin-right: 0;
  margin-top: 3.3vh;
}
.div--AboutUs--card ul li {
  font-size: 2vw;
  line-height: 150%;
}
.div--AboutUs--card ul li a {
  color: white;
}
.div--AboutUs--card--mobile ul li {
  font-size: 5vw;
 
}
.div--AboutUs--card ul img {
  max-width: 40%;
  position: relative;
  top:-7%;
}
.div--AboutUs--card--mobile ul img {
  max-width: 50%;
top: -3vh;
}
/* pricing page  */
.div--pricingPage{
  z-index: 1000;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(1,26,59,1) 100%);
}
.PricingPage__h3{
  font-size: 6vw;
  margin: 0;
  color: white;
  text-align: center;
  border: none;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(1,26,59,1) 100%);

}
.PricingPage__h3--mobile{
  font-size: 9vw;

}
.ul--pricing{
  margin-top: 2vh;
  margin-bottom: 1vw;
  width: 20%;
  color: white;
  border-radius: 8px;
  margin-left: 5%;
  padding: 5% 3%;
  background: rgb(7,12,46);
  background: linear-gradient(135deg, rgba(7,12,46,1) 25%, rgba(33,46,130,1) 99%);
  text-align: center;
}

.ul--pricing h3{
  font-size: 2.5vw;
  text-transform: uppercase;
  margin: 0;


}
.ul--pricing li{
  text-align: left;
  padding-top: 8%;
}
.ul--pricing--mobile{
width: 84%;

}
.ul--pricing--mobile h3{
  font-size: 5vw;
}
.ul--pricing--mobile li{
  font-size: 5vw;
}
/* social media  */
.container--socialMedia{

  text-align: center;
  background-color: black;

  font-size: 3vw;
  margin: 0;
  transition: top 0.2s;
  padding: 10px 0;
}
.container--socialMedia i{
  position: relative;

  transition: top 0.6s;
}
.container--socialMedia i:hover{
  position: relative;

  top:-1vh;
}
.container--socialMedia--mobile{
  margin-top: 1vh;
  font-size: 6vw !important;
}

/* contcat methods  */

footer{
  background: rgb(2,0,36);
  background: linear-gradient(100deg, rgba(2,0,36,1) 0%, rgba(3,3,37,1) 35%, rgba(0,42,51,1) 100%);
}
footer h3{
  text-align: center;
  font-size: 5vw;
  margin-top: 0%;
  padding-top: 2%;
  margin-bottom: 2%;
  color: white;
}
footer p{
text-align: center;
color: white;
font-size: 3vw;
margin: 0;
margin-bottom: 2%;
}
.footer--mobile h3{
  font-size: 7vw;
}
.footer--mobile p{
  font-size: 4vw;
}
.form--contactMethods{

  display: flex;
}
.form--contactMethods div{
  width: 40%;
  margin-left: 10%;
}
.form--contactMethods p{
  margin-top: 1vh;
}
.form--contactMethods div a {
  display: block;
  
  color: white;
  text-decoration: underline;
}
.p--viaContact{
  
  text-align: left;
  font-size: 2vw;
}
.form--contactMethods form{
  display: flex;
  width: 80%;
  margin-left: 5%;
  flex-direction: column;
  padding-bottom:1%;

}
.form--contactMethods form input,.form--contactMethods form textarea {

  width: 60%;
  margin-left: 20%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 3%;
  color:black;
  padding: 2% 0;
}
.form--contactMethods form input::placeholder,.form--contactMethods form textarea::placeholder{
  text-align: center;
  color: #111;

}
.form--contactMethods form input[type="submit"]{
  background-color: white;
  border: none;
  width: 50%;
  color: black;
  font-size: 100%;
  font-weight: bolder;
  text-transform: uppercase;
  margin-left: 25%;

}

.form--contactMethods--mobile{
flex-direction: column;
}
.form--contactMethods--mobile div{
  width: 100%;
  text-align: center;
  padding-left: 0;
  margin-left: 0;
}
.form--contactMethods--mobile div p {
  font-size: 5vw;
  text-align: center;
}

.form--contactUs--mobile{
  width: 100vw !important;
margin: 0 !important;

}

/* atropos */
.h3--technologiesused{
  margin-top: 2vh !important;

 font-size: 5.5vw !important;
}
.container--technologiesUsed{


  padding: 3vh 0;
  display: flex;
  flex-wrap: wrap;

 margin-left: 5vw;
}
.container--technologiesUsed div {
  width: 20vw;
  align-self: center;
}
.my-atropos {
  width: max-content;
  height: max-content;
  margin-left: 1vw;



}

.my-atropos img {
  width: 60%;
  height: auto;
  contain: content;

  max-width: 140%;
text-align: center;
padding-left: 20%;

}
.container--technologiesUsed--mobile{
  margin-left: 3vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.container--technologiesUsed--mobile div{
  width: 15vw;
}
.container--technologiesUsed--mobile div img{
  width: 100%;
  max-width: 200%;
  align-self: center;
  justify-self: center;
  padding-left: 0;

}
