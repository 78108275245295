/* day mode */

/* welcomepage */
.container__div--welcomePage--day{

    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(226,248,255,1) 67%);
  
  }
  .div--welcomePage__message--day{
    color: black;
  }
  .Button--welcomePage--day{
    background-color: black;
  
  
  }
  .Button--welcomePage--day a {
    color: white;
  }
  
  /* About Us */
  .div--AboutUs--day{
   background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(226,248,255,1) 67%);
  }
  .div--AboutUs--day h3 , .div--AboutUs--day div p, .div--AboutUs--day div a{
    color: black;
  }
  /* pricing page  */
  .div--pricingPage--day{
    background: rgb(255,255,255);
  
  }
  .PricingPage__h3--day{
    background: rgb(255,255,255);
    color: black;
  }
  .ul--pricing--day {
    background: rgb(10,24,121);
  background: linear-gradient(90deg, rgba(10,24,121,1) 2%, rgba(22,104,180,1) 57%);
  
  }
  
  /* footer */
  .footer--day{
    background: rgb(250,250,250);
    background: linear-gradient(90deg, rgba(250,250,250,1) 23%, rgba(225,225,233,1) 47%, rgba(235,235,235,1) 100%);
  }
  .footer--day h3, .footer--day p{
    
    color:black;
  }
  .form--contactMethods--day div a{
    color: black;
  }
  .form--contactUs--day input, .form--contactUs--day textarea{
    border: 1px solid black !important;
  
  }
  .form--contactUs--day input[type="submit"]{
background-color: black !important;
color: white !important;
  }

  /* nav */
  .nav--mobile--day{
    background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 57%);
  color: black;
  }
  .App-header--day{
    background: rgb(250,250,250);
    background: linear-gradient(90deg, rgba(250,250,250,1) 23%, rgba(225,225,233,1) 47%, rgba(154,222,179,1) 100%);
  }
  .nav__ul--day li a {
    color: black;
  }
  .nav--day{
    background: rgb(250,250,250);
    background: linear-gradient(90deg, rgba(250,250,250,1) 23%, rgba(225,225,233,1) 47%, rgba(154,222,179,1) 100%);
}

.nav--day ul li  a{
  color: black;
}
/* logo  */
.logo--day{
  filter: none;
}